import React from 'react'
import './LoyaltyProgram.scss'
import alpLogo from '../../assets/images/brands/alp.webp'
import sideImg from '../../assets/images/premier-circle-bg.webp'

const membershipData = [
  {
    membershipTier: 'Platinum',
    validity: 'Lifetime',
    id: '1'
  },
  {
    membershipTier: 'Titanium',
    validity: 'Three (3) Years',
    id: '2'
  },
  {
    membershipTier: 'Gold',
    validity: 'One (1) Year',
    id: '3'
  }
]

const LoyaltyProgram = () => {
  return (
    <div className="loyalty-container">
      <div className="loyalty-container-image">
        <img loading="lazy" src={sideImg} alt="bg" />
      </div>
      <div className="loyalty-container-content">
        <div className="alp-logo">
          <img loading="lazy" src={alpLogo} alt="bg" />
        </div>
        <h2 className="title">Premier Circle Loyalty Program</h2>
        <h3>TERMS AND CONDITIONS</h3>
        <ol className="loyalty-list">
          <li className="loyalty-items">
            <strong>ALP PREMIER CIRCLE LOYALTY PROGRAM</strong>
            <br />
            <br />
            <ol className="loyalty-list">
              <li className="loyalty-items">
                My continued participation in ALP Premier Circle Loyalty Program, also referred to as the “Program”, a program run and
                managed by the AyalaLand Premier, Inc. – Premier Circle Division (hereinafter referred to as “ALPI”), shall constitute my
                acceptance of this Terms and Conditions, as the same may be amended, modified and supplemented from time to time.
              </li>
              <li className="loyalty-items">
                My membership account is under my name, and is not transferable. Enrolment of several people in a single account (joint
                account) and multiple enrollments by a single person are strictly not allowed.
              </li>
              <li className="loyalty-items">
                A change in circumstances on my record will be accepted upon written notification to ALPI. Proof of change is required to be
                submitted for this purpose to: premier.circle@ayalalandpremier.com.
              </li>
              <li className="loyalty-items">
                Membership in the Program shall be valid, as follows:
                <br />
                <br />
                <div className="grid-container">
                  <div className="grid-item-title">Membership Tier</div>
                  <div className="grid-item-title">Validity</div>
                  {membershipData.map((data) => (
                    <>
                      <div key={data.id} className="grid-item-content">
                        {data.membershipTier}
                      </div>
                      <div key={data.id} className="grid-item-content">
                        {data.validity}
                      </div>
                    </>
                  ))}
                </div>
                <br />
                Memberships shall be renewed, subject to the discretion and approval of ALPI. In case of approval by ALPI, I hereby agree to
                such renewal, subject to the same terms and conditions, as may be amended by ALPI from time to time.
              </li>
              <li className="loyalty-items">
                In the event that membership in the Program is discontinued, ALPI shall retain personal information, sensitive personal
                information, and privileged information (collectively, <b>“Personal Data”</b>) for a maximum period of one (1) year after
                member has exited the Program.
              </li>
              <li className="loyalty-items">
                ALPI reserves the right to review my account at any time for compliance with these Terms and Conditions without prior notice
                being given.
              </li>
            </ol>
          </li>
          {/* =================================================== */}
          <li className="loyalty-items">
            <strong>GENERAL TERMS FOR THE PROGRAM</strong>
            <br />
            <br />
            <ol className="loyalty-list">
              <li className="loyalty-items">
                The rewards and privileges of the Program, where applicable, are subject to availability and are redeemable on a first-come,
                first-served basis. ALPI reserves the right, in its absolute discretion, to cancel, change, substitute or discontinue the
                rewards/privileges/benefits, or make any amendments to, change or substitute or impose new conditions relating to the
                rewards/privileges/benefits at any time with or without prior notice to me. The decision of ALPI shall be final and not
                subject to appeal.
              </li>
              <li className="loyalty-items">
                ALPI may revise the Program rules or Program benefits without any prior notice. ALPI reserves the right at any time to
                change or discontinue, without limitation, the following:
                <ol className="loyalty-list-sub">
                  <br />
                  <li>Program partners or participating organizations.</li>
                  <li>The rewards/privileges structure, and the availability of rewards/privileges.</li>
                  <li>The features of special offers and promotions</li>
                  <br />
                </ol>
                I likewise acknowledge that rewards/privileges/benefits from Program partners are dependent on the partner’s continued
                participation in the Program.
              </li>
              <li className="loyalty-items">
                Merchants are not obliged to reserve stock or goods for Program members, and neither ALPI nor its merchants shall be liable
                or responsible in the event of any unavailability of rewards/privileges/benefits.
              </li>
              <li className="loyalty-items">
                Redemption of the Program’s rewards/privileges/benefits shall be subject to the individual merchant’s and/or the Program’s
                participating companies’ terms and conditions.
              </li>
            </ol>
          </li>
          {/* ===================================================== */}
          <li className="loyalty-items">
            <strong>GENERAL TERMS & CONDITIONS</strong>
            <br />
            <br />
            <ol className="loyalty-list">
              <li className="loyalty-items">
                ALPI reserves the right, at any time and without prior notice or limitation, to amend, modify or supplement any of these
                Terms and Conditions, or to terminate any (or all) of the programs mentioned herein.
              </li>
              <li className="loyalty-items">
                ALPI reserves the right to extend, renew and terminate the Program or any part thereof, at any time with or without notice.
                ALPI is not liable to members for any special, consequential, exemplary, indirect damages or for any loss of value,
                opportunities or benefits that may arise as a result of any changes made to the Program.
              </li>
              <li className="loyalty-items">
                ALPI shall not be liable for any product or service offered or promised by a participating merchant. ALPI shall not be held
                liable for any action that may arise as a result of patronizing a participating merchant.
              </li>
              <li className="loyalty-items">
                ALPI may assign or subcontract its rights and obligations under these Terms and Conditions in favor of any of its
                subsidiaries, affiliates, sub-contractors or appointed agents. ALPI shall not be responsible to me for any delay in the
                performance or non-performance of its sub-contractors and/or agents due to causes beyond its reasonable control.
              </li>
              <li className="loyalty-items">
                Without prejudice to the application of Clause 3.11, ALPI shall not be in breach of its obligation of confidentiality if
                disclosure of my confidential information is pursued in conjunction with its right to assign or subcontract, an order of
                court, or is required by law or any governmental or regulatory authority. To the extent permitted by law and if the
                disclosure is not required with immediate effect, ALPI shall give me prior notice of such disclosure.
              </li>
              <li className="loyalty-items">
                I am fully responsible for keeping myself informed of the Terms and Conditions of the Program, including any amendment,
                modification or supplement thereto.
              </li>
              <li className="loyalty-items">
                It is my responsibility to keep my mailing address and email address up to date. Any communication sent to me will be
                regarded as delivered when posted to a mailing address, or sent to my email address, on record. ALPI will not be responsible
                for any delayed, misdirected or lost mail or email.
              </li>
              <li className="loyalty-items">
                No forbearance, delay or failure on ALPI’s part to exercise any power or right under these Terms and Conditions shall
                operate as a waiver of such power or right, nor shall any single or partial exercise of such power or right preclude any
                further exercise of that or any power or right.
              </li>
              <li className="loyalty-items">
                These Terms and Conditions are governed by and construed in accordance with the laws of the Republic of the Philippines. I
                agree to submit myself to the non-exclusive jurisdiction of the courts of Makati City, Philippines.
              </li>
              <li className="loyalty-items">
                All information contained here is correct and current at the time of publication and is subject to change.
              </li>
              <li className="loyalty-items">
                To maintain and implement the Program in connection with its marketing, communication, analysis and research objectives and
                for me to continue enjoying the rewards, privileges and benefits thereof, I acknowledge and agree that AyalaLand Premier,
                Inc., its directors, officers, employees, service providers, authorized representatives and agents (collectively,
                <b>“ALPI”</b>):
                <ol className="loyalty-list-sub">
                  <br />
                  <li>
                    will collect, obtain, use, store, process and consolidate (collectively, “process” or “processing”) information about me
                    (including my sensitive Personal Data, contact details, demographic information and account details) to determine
                    suitable promos, events, activities, products and services that I can participate in or avail; and
                  </li>
                  <li>
                    may outsource the processing thereof to service providers, whether within or outside the Philippines, with my consent
                    herein given.
                  </li>
                </ol>
              </li>
              <li className="loyalty-items">
                By continuing with my Program membership or by availing of the rewards/privileges/benefits of the Program, I authorize ALPI,
                its related companies (including member-companies), assignees and their respective outsourced service providers to use,
                share and disclose my information for any or all of the following purposes:
                <ol className="loyalty-list-sub">
                  <br />
                  <li>
                    To facilitate the administration, provision, implementation and monitoring of my rewards, benefits and privileges as
                    Program member;
                  </li>
                  <li>
                    To contact or reach out to me through personal visits, phone calls, mail, email, SMS, social media, and e-commerce
                    platforms or any other type of electronic facility which the ALPI may deem appropriate and provide me with marketing or
                    promotional information and materials relating to promos, events, activities, products and services which I may find
                    interesting;
                  </li>
                  <li>
                    To develop, enhance and provide improvements/upgrades in its systems and business processes, including but not limited
                    to data analytics and automated processing;
                  </li>
                  <li>
                    To carry out and implement the Program promos, events, activities, products and services which I avail of or participate
                    in from time to time. For any or all of the foregoing purposes, I expressly authorize, from time to time, under an
                    obligation of confidentiality: (i) the Bank of the Philippine Islands, Globe Telecom, Ayala Land, Inc., their respective
                    subsidiaries and affiliates (collectively, the “Ayala Group of Companies”) and the marketing and promotional partners
                    and third parties, whether within or outside the Philippines (the “Program partners”), to disclose and share to ALPI my
                    information as appearing in their respective records; and (ii) for the ALPI to process my updated information obtained
                    from the Ayala Group of Companies and the Program partners. I agree to inform ALPI of any changes relating to my
                    information through its{' '}
                    <a href="mailto:premier.circle@ayalalandpremier.com">
                      <u>premier.circle@ayalalandpremier.com</u>
                    </a>
                  </li>
                </ol>
                <br />
                The foregoing constitutes my express consent under the applicable confidentiality and data privacy laws of the Philippines,
                and other jurisdictions, and the Privacy Policy of ALPI. I agree to hold ALPI, the Ayala Group of Companies, the Program
                partners and their respective authorized representatives and outsourced service providers free and harmless from any and all
                liabilities, claims, damages, suits of whatever kind and nature, that may arise in connection with the implementation and
                compliance with the authorization conferred by me under these Terms and Conditions.
                <br />
                <br />I acknowledge that I have received, read, and understood the Program requirements and the foregoing Terms and
                Conditions and Privacy Policy. By signing this document, I am agreeing to the foregoing Terms and Conditions and Privacy
                Policy of the membership.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default LoyaltyProgram
